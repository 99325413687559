/* About FELD
 *
 */

import React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Signup from '../components/Signup'

import styles from './articles.module.styl'

const signupBase = styles.signup
const signupActive = [signupBase, styles.signupActive].join(' ')

const title = 'FELD Berlin. Articles about research engineering and data foundations'
const description = `
  We have written about topics like data privacy, generative modelling and
  data platforms. Read about data foundations and research engineering in our
  newsletter.
`

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = { signupActive: true }
  }

  render() {
    const { data } = this.props
    const posts = data.allMdx.edges

    return (
      <Layout location={ this.props.location } footer={ "minimal" }>
        <SEO title={ title } description={ description } />
        <div className={ styles.articles }>
          {posts.map(({ node }) => {
            let slug = node.fields.slug.replace(/\/$/, "")
            return (
              <article key={ slug }>
                <Link to={ slug }>
                  <div className={ styles.image }>
                    <Image fluid={node.frontmatter.mainImage.childImageSharp.fluid} />
                  </div>
                  <div className={ styles.body }>
                    <div className={ styles.summary }>
                      <span className={ styles.title }>
                        {node.frontmatter.title}
                      </span>
                      <span className={ styles.kicker }>
                        {node.frontmatter.kicker}
                      </span>
                      <span className={ styles.intro }>
                        {node.frontmatter.intro}
                      </span>
                      <span className={ styles.author }>
                        Written by {node.frontmatter.author}
                      </span>
                    </div>
                    <div className={ styles.category }>
                      {node.frontmatter.category}
                    </div>
                  </div>
                </Link>
              </article>
            )
          })}
        </div>

        <div className={ this.state.signupActive ? signupActive : signupBase } onClick={ () => this.setState({ signupActive: true }) }>
          <div>Sign up to the FELD Newsletter. <span>Get articles like these delivered to your inbox every two weeks. { description }</span></div>
          <div className={ styles.tinySignup }><Signup size={ "tiny" } /></div>
        </div>
      </Layout>
    )
  }
}

export default Articles

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {published: {eq: true}}}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            intro
            kicker
            category
            mainImage {
              childImageSharp {
                fluid(maxWidth: 620) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
